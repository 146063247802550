import {ActionReference, GameObjectReference, GameState} from "@shared/types";
import {css, html, LitElement, TemplateResult} from "lit";
import {customElement} from "lit/decorators.js";
import {getState, performAction} from "../services/routeService";
import {gameService} from "../services/gameService";

@customElement("game-canvas")
export class GameCanvas extends LitElement {
    public static styles = css`
        .title {
            text-align: center;
            margin-top: 10px;
        }


        //.header img:nth-child(n + 2) {
        //    position: absolute;
        //}

        //.content p:last-of-type {
        //    margin: 0;
        //}
    `;

    private roomTitle?: string;
    private lastState?: GameState;
    private actions?: ActionReference[];
    private gameObjects?: GameObjectReference[];

    public connectedCallback(): void {
        super.connectedCallback();

        gameService.addEventListener("prompt-submit", e => {
            this.onPrompt(e.data)
                .then(() => {}, () => {}); // Please leave me alone ESLint
        });

        void this.refreshState();
    }

    private async refreshState(): Promise<void> {
        const state: GameState = await getState();

        this.updateState(state);
    }

    private updateState(state: GameState): void {
        //Reset the component
        this.lastState = state;
        this.actions = state.actions;
        this.gameObjects = state.objects;
        this.roomTitle = state.roomTitle;

        gameService.dispatchEvent("image-change", state.roomImages);
        gameService.dispatchEvent("room-change", state.roomTitle);
        gameService.dispatchEvent("actions-change", state.actions);
        gameService.dispatchEvent("gameobjects-change", state.objects);
        gameService.dispatchEvent("add-text", state.text);

        this.requestUpdate();
    }

    public static isMultiAction(alias: string): boolean {
        return alias.startsWith("talk:") || alias.startsWith("use:");
    }

    private performLocalAction(action: string): GameState | undefined {
        if (this.lastState) {
            if (action === "help") {
                return {
                    ...this.lastState,
                    text: [
                        "Available actions:",
                        ...this.actions
                            ?.filter(a => !a.hidden)
                            .map(a => {
                                const name: string = GameCanvas.isMultiAction(a.alias) ? a.alias.split(":")[2] : a.alias;
                                let entry: string = `- <span style=\"color: cyan;\">${name}</span>`;
                                for (let i: number = 0; i < a.objectCount; i++) {
                                    entry += " <em>[object]</em>";
                                }
                                return entry;
                            }) || []
                    ]
                };
            }
        }

        return undefined;
    }

    private async onPrompt(prompt: string): Promise<void> {
        gameService.dispatchEvent("add-text-immediate", [`<em>${this.roomTitle}> ${prompt}</em>`]);

        const promptWords: string[] = prompt.split(" ");
        let state: GameState | undefined;

        state = this.performLocalAction(promptWords[0]);
        if (state) {
            this.updateState(state);

            return;
        }

        const action: ActionReference | undefined = this.actions?.find(a => {
            // Handle talk actions differently
            if (GameCanvas.isMultiAction(a.alias)) {
                return a.alias.split(":")[2] === promptWords[0];
            }

            return a.alias === promptWords[0];
        });

        promptWords.shift(); // Remove the action alias

        if (!action) {
            gameService.dispatchEvent("add-text", ["I dont know how to do that. Type <span style=\"color: cyan;\">help</span> for a list of available actions."]);

            this.requestUpdate();
            return;
        }

        if (promptWords.length > 0 && promptWords.length >= action.objectCount) {
            const gameObjects: (GameObjectReference | undefined)[] = promptWords
                .map(word => this.gameObjects?.find(o => o.alias === word));

            if (gameObjects.some(o => o === undefined)) {
                gameService.dispatchEvent("add-text", ["I can't do that with that."]);

                this.requestUpdate();
                return;
            }

            state = await performAction(action.alias, promptWords);
        } else {
            if (action.objectCount > 0) {
                gameService.dispatchEvent("add-text", ["What do I do that with?"]);

                this.requestUpdate();
                return;
            }

            state = await performAction(action.alias);
        }

        if (!state) {
            return;
        }

        this.updateState(state);
    }

    protected render(): TemplateResult {
        return html``;
    }

    // private renderTitle(): TemplateResult {
    //     if (this.roomTitle) {
    //         return html`<div class="title">${this.roomTitle}</div>`;
    //     }
    //
    //     return html`${nothing}`;
    // }
}
