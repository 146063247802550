import {customElement} from "lit/decorators.js";
import {css, html, LitElement, TemplateResult} from "lit";
import {gameService} from "../services/gameService";

type Entry = {
    element: HTMLParagraphElement,
    currentHtml: string,
    fullHtml: string,
}

@customElement("console-thing")
export class Console extends LitElement {
    public static styles = css`
        .content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
        }

        .content p {
            margin: 0 0 10px 0;
        }

        .content p em {
            color: darkgray;
        }
    `;

    private entries: Entry[] = [];

    public render(): TemplateResult {
        return html`
            <div class="content">

            </div>
        `;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        gameService.addEventListener("add-text", e => {
            const text: string[] = e.data;

            text.forEach(textEntry => {
                const element: HTMLParagraphElement = document.createElement("p");
                element.style.display = "none";

                this.entries.push({
                    element,
                    currentHtml: "",
                    fullHtml: textEntry,
                });

                this.renderRoot.querySelector(".content")?.append(element);
            });
        });

        gameService.addEventListener("add-text-immediate", e => {
            const text: string[] = e.data;

            text.forEach(textEntry => {
                const element: HTMLParagraphElement = document.createElement("p");
                element.innerHTML = textEntry;

                this.renderRoot.querySelector(".content")?.append(element);
            });
        });

        setInterval(() => {
            if (this.entries.length > 0) {
                const entry: Entry = this.entries[0];

                if (entry.currentHtml.length < entry.fullHtml.length) {
                    entry.element.style.display = "block";

                    // Element is not yet fully rendered
                    let currentIndex: number = entry.currentHtml.length;
                    let nextPart: string = entry.fullHtml.charAt(currentIndex);

                    // Handle html tags in the string
                    if (nextPart === "<") {
                        // Check for the closing ">" one char before the end of the string
                        while (nextPart.charAt(nextPart.length - 1) !== ">") {
                            currentIndex++;
                            nextPart += entry.fullHtml.charAt(currentIndex);
                        }
                    } // `<d delay="1"></d>`

                    entry.currentHtml += nextPart;
                    entry.element.innerHTML = entry.currentHtml;
                } else {
                    // Element is complete, we push it out the bottom
                    this.entries.shift();
                }

                // Scroll down
                const content: HTMLElement = document.querySelector(".content")!;
                content.scrollTop = content.scrollHeight;
            }
        }, 20);
    }
}