import {customElement, state} from "lit/decorators.js";
import {css, html, LitElement, TemplateResult} from "lit";
import {gameService} from "../services/gameService";
import {ActionReference, GameObjectReference} from "@shared/types";
import {GameCanvas} from "./GameCanvas";

@customElement("prompt-thing")
export class Prompt extends LitElement {
    public static styles = css`
        .footer {
            margin-top: auto;
            align-self: flex-end;
            display: flex;
            width: 100%;

            color: darkgray;
            font-style: italic;
        }
        
        .prompt {
            //width: 100%;
            flex-grow: 1;
            font-family: "Onesize";
            font-size: 1em;
            outline: none;
            background: none;
            //color: white;
            border: none;
            padding-left: 12px;

            color: darkgray;
            font-style: italic;
        }
    `;

    @state()
    private roomTitle?: string;
    private actions?: ActionReference[];
    private gameObjects?: GameObjectReference[];

    public render(): TemplateResult {
        return html`
            <div class="footer">
                ${this.roomTitle}>
                <input type="text" 
                       class="prompt" 
                       @keydown=${this.promptKeyDown}>
            </div>
        `;
    }

    private promptKeyDown(e: KeyboardEvent): void {
        if (e.key === "Enter") {
            const element: HTMLInputElement = e.target as HTMLInputElement;
            const prompt: string = element.value;
            element.value = "";

            gameService.dispatchEvent("prompt-submit", prompt);
        } else if (e.key === "Tab") {
            e.preventDefault();

            const element: HTMLInputElement = e.target as HTMLInputElement;
            let prompt: string = element.value;
            const words: string[] = prompt.split(" ");

            if (words.length > 1) {
                // Autocomplete gameobjects
                const object: GameObjectReference | undefined = this.gameObjects?.find(o => o.alias.startsWith(words[words.length - 1]));
                if (object) {
                    prompt = [...words.slice(0, words.length - 1), object.alias].join(" ");
                }
            } else {
                // Autocomplete actions
                const action: ActionReference | undefined = this.actions?.find(a =>
                    a.alias.startsWith(words[0]) && !a.hidden && !GameCanvas.isMultiAction(a.alias));
                if (action) {
                    prompt = action.alias;
                }
            }

            element.value = prompt;
        }
    }

    public connectedCallback(): void {
        super.connectedCallback();

        gameService.addEventListener("room-change", e => {
            this.roomTitle = e.data;
        });
        gameService.addEventListener("actions-change", e => {
            this.actions = e.data;
        });
        gameService.addEventListener("gameobjects-change", e => {
            this.gameObjects = e.data;
        });
    }
}