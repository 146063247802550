import {customElement, state} from "lit/decorators.js";
import {css, html, LitElement, nothing, TemplateResult} from "lit";
import {GameImage} from "@shared/types";
import {gameService} from "../services/gameService";

@customElement("image-canvas")
export class ImageCanvas extends LitElement {
    public static styles = css`
        .header {
            height: 50%;
            position: relative;
        }

        .header img {
            width: 100%;
            image-rendering: pixelated;
            position: absolute;
            pointer-events: none;
        }

        .fade-in {
            animation: fadein 8s;
        }

        @keyframes fadein {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    `;

    @state()
    private roomImages?: GameImage[];

    protected render(): TemplateResult {
        if (this.roomImages && this.roomImages.length > 0) {
            return html`
                <div class="header">
                    ${this.roomImages?.map((image) => {
                        if (typeof image === "string") {
                            return html`<img src="/assets/img/rooms/${image}"/>`;
                        } else if (image.audio) {
                            const audio: HTMLAudioElement = document.createElement("audio");
                            audio.src = `/assets/audio/rooms/${image.name}`;
                            audio.autoplay = true;
                            audio.loop = image.loop || false;
                            setTimeout(() => {
                                audio.play().then(() => {
                                }, (e) => {
                                    console.warn("Failed to play audio", e);
                                });
                            }, 10);
                            return html`${audio}`;
                        } else {
                            const htmlImg: HTMLImageElement = document.createElement("img");
                            htmlImg.src = `/assets/img/rooms/${image.name}`;
                            if (image.fadeIn) {
                                htmlImg.classList.add("fade-in");
                            }
                            return html`${htmlImg}`;
                        }
                    })}
                </div>
            `;
        }

        return html`${nothing}`;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        gameService.addEventListener("image-change", (e) => {
            const data: GameImage[] = e.data;

            if (this.roomImages?.length === data.length && this.roomImages
                ?.every((img, i) => data
                    .findIndex(newImg => this.imageEquals(img, newImg)) === i)) {
                // Lists must be equal still
                return;
            }

            this.roomImages = e.data;
        });
    }

    private imageEquals(img1: GameImage, img2: GameImage): boolean {
        if (img1 === img2) {
            return true;
        }

        if (typeof img1 !== "string" && typeof img2 !== "string") {
            return img1.name === img2.name;
        }

        return false;
    }
}